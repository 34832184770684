.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--Color-90, #0B6E4F);
  background-color: #F0FFFA;
  color: var(--Color-90, #0B6E4F);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
}

.mySwiper .swiper-button-next::after,
.mySwiper .swiper-button-prev::after {
  font-size: 16px;
}

.button-custom-active {
  --button-border-color: #FF0000; 
  --button-icon-color: #FF0000; 
}

@media (min-width: 320px) and (max-width: 767px) {
  .swiper-button-next, .swiper-button-prev {
    display: none!important;
  }  
}

