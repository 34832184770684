.categories-swiper .swiper-pagination {
    position: relative;
    margin-top: 30px; 
    text-align: center;
}

.categories-swiper .swiper-pagination-bullet {
    width: 12px; 
    height: 12px;
    background-color: #CCCCCC; 
    opacity: 1; 
}

.categories-swiper .swiper-pagination-bullet-active {
    width: 30px; 
    background-color: var(--Color-100, #014C35);
    border-radius: 15px;
  }
  
 