* {
    box-sizing: border-box;
}

body {
    // background: #fef9f3;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

